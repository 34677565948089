import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import Logrocket from 'logrocket';

const reducer = (state, { type, value: message }) => {
  if (type !== 'set') return state;
  return Object.assign({}, state, {
    message
  });
}

const createStore = () => reduxCreateStore(reducer, applyMiddleware(Logrocket.reduxMiddleware()))
export default createStore
